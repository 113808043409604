<template>
  <div style="overflow: auto;" v-loading="dataLoading">
    <h1>Meter Totalizer Log</h1>
    <div style="display: flex; justify-content: space-between;">
      <div style="display: flex; gap: 5px;">
        <div v-if="permissions.hasSysAdminPermission">
          <div class="filter-label">Company <span style="color:red">*</span></div>
          <el-select v-model="companyId" placeholder="Select" filterable @change=handleCompanyChange>
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.companyName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <div class="filter-label">Ship <span style="color:red">*</span></div>
          <el-select v-model="shipImoNo" placeholder="Select" filterable :disabled="!companyId" @change="handleShipChange" >
            <el-option
              v-for="item in ships"
              :key="item.imo"
              :label="item.shipName"
              :value="item.imo">
            </el-option>
          </el-select>
        </div>
        <div>
          <div class="filter-label">Date Range <span style="color:red">*</span></div>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            format="yyyy-MM-dd"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :disabled="!shipImoNo"
            @change="handleDateRangeChange" >
          </el-date-picker>
        </div>
        <div>
          <div class="filter-label">Meter Serial No. <span style="color:red">*</span></div>
          <el-select v-model="meterSerialNo" filterable placeholder="Select" @change="handleMeterSerialNoChange">
            <el-option
              v-for="item in meterSerialNos"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div>
          <div style="padding-top: 20px;"></div>
          <el-button icon="el-icon-search" circle :disabled="!isLogSearchable" @click="getLogs"></el-button>
        </div>
      </div>
      <div>
        <div style="padding-top: 20px;"></div>
        <el-button type="primary" round plain @click="exportLogs" :disabled="logs.length === 0">View PDF</el-button>
      </div>
    </div>
    <div style="padding: 10px 0px;">
      <el-table
        :data="logs"
        stripe
        :empty-text="!isLogSearchable || searchLogsCount == 0 ? 'Please fill in the required fields and click the search button to proceed' : 'No data found'"
        style="width: 100%; height: calc(100vh - 15vw); overflow: scroll;">
        <el-table-column type="index">
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="dateTime"
          label="Date / Time">
          <template slot-scope="scope">
            <div>{{ scope.row.dateTime ? serverSGDateTimeFormat(new Date(scope.row.dateTime)) : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="fuelTypeCode"
          label="Product / Grade">
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="address"
          label="Terminal Loaded / Vessel Delivered">
          <template slot-scope="scope">
            <div>{{ scope.row.jobType === null ? scope.row.otherType : (scope.row.jobType === 'LOADING' ? scope.row.floaterName || scope.row.location : scope.row.receivingShipName) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="bunkerMeteringTicketNo"
          label="BMT No.">
          <template slot-scope="scope">
            <div>{{ scope.row.bunkerMeteringTicketNo || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="address"
          label="BDN No. / CQ No.">
          <template slot-scope="scope">
            <div>{{ scope.row.jobType === null ? '-' : (scope.row.jobType === 'LOADING' ? scope.row.cqNo : scope.row.bdnNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          label="Loading Totalizer Reading">
          <el-table-column
            header-align="center"
            prop="mtX"
            label="Start">
          </el-table-column>
          <el-table-column
            header-align="center"
            prop="mtY"
            label="End">
          </el-table-column>
        </el-table-column>
        <el-table-column
          header-align="center"
          label="Delivery Totalizer Reading">
          <el-table-column
            header-align="center"
            prop="mtA"
            label="Start">
          </el-table-column>
          <el-table-column
            header-align="center"
            prop="mtB"
            label="End">
          </el-table-column>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="quantity"
          label="Meter Qty Loaded / Delivered">
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="cargoTankSequence"
          label="Cargo Tank Sequence">
          <template slot-scope="scope">
            <div>{{ scope.row.cargoTankSequence || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="cargoOfficerName"
          label="Cargo Officer">
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="remarks"
          label="Remark">
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: flex-end;">
      <el-pagination
        style="position: absolute;"
        background @size-change="handlePageSizeChange" @current-change="handlePageNumberChange"
        :page-size.sync="pagination.pageSize" :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="pagination.pageNumber" layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sortByAlphabetical, openBlobUrlInNewTab, toSgUtcDate, serverSGDateTimeFormat  } from "@/utils";
export default {
  name: 'MeterTotalizerLog',
  data () {
    return {
      dataLoading: false,
      logs: [],
      companies: [],
      ships: [],
      meterSerialNos: [],
      companyId: null,
      shipImoNo: null,
      dateRange: null,
      meterSerialNo: null,
      fromDt: null,
      toDt: null,
      timezoneOffset: 480,
      searchLogsCount: 0,
      pagination: {
        pageSize: 20,
        pageNumber: 1,
        total: 0
      },
    }
  },
  computed: {
    ...mapState(["currentCompany", "permissions"]),
    isMeterSerialNoSearchable () {
      return this.companyId && this.shipImoNo && this.dateRange
    },
    isLogSearchable () {
      return this.isMeterSerialNoSearchable && this.meterSerialNo
    },
    shipId() {
      const ship = this.ships.find( item => item.imo == this.shipImoNo )
      return ship?.id
    }
  },
  mounted() {
    if (this.permissions.hasSysAdminPermission) {
      this.getOnlineCompanies()
    }
    else {
      this.companyId = this.currentCompany.id
      this.getShipsByCompany(this.companyId)
    }
  },
  methods: {
    sortByAlphabetical,
    serverSGDateTimeFormat,
    openBlobUrlInNewTab,
    async getOnlineCompanies () {
      this.dataLoading = true;

      try {
        const res = await this.$request.get({
          url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&offline=false`
        })

        if (res?.code === 1000) {
          const filtered =
            res.data?.records.filter(
              (c) => c.companyName && c.companyName !== "noCompany"
            ) ?? [];
          this.companies = this.sortByAlphabetical(filtered, "companyName");
        }
      } catch (e) {
        this.$message.error('Error retrieving list of companies')
      } finally {
        this.dataLoading = false
      }

    },
    async getShipsByCompany (companyId) {
      this.dataLoading = true;

      try {
        const res = await this.$request.get({
          url: `${this.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999&organizationId=${companyId}`
        })

        if (res?.code === 1000) {
          this.ships = res.data.records || []
        }
      } catch (e) {
        this.$message.error('Error retrieving list of ships')
      } finally {
        this.dataLoading = false
      }
    },
    async getMeterSerialNoByImo () {
      if (!this.isMeterSerialNoSearchable) return
      this.dataLoading = true;

      try {
        const res = await this.$request.get({
          url: `${this.$apis.meterTotalizerLogs}/meter_serial_no?shipId=${this.shipId}&imoNo=${this.shipImoNo}&fromDt=${this.fromDt}&toDt=${this.toDt}&timezoneOffset=${this.timezoneOffset}`
        });

        if (res?.code === 1000) {
          this.meterSerialNos = res.data || []
        }
      } catch (e) {
      } finally {
        this.dataLoading = false
      }
    },
    async getLogs () {
      this.dataLoading = true;

      const filterQuery = `shipId=${this.shipId}&imoNo=${this.shipImoNo}&fromDt=${this.fromDt}&toDt=${this.toDt}&meterSerialNo=${this.meterSerialNo}&timezoneOffset=${this.timezoneOffset}`

      try {
        const res = await this.$request.get({
          url: `${this.$apis.meterTotalizerLogs}?pageNumber=${this.pagination.pageNumber - 1}&pageSize=${this.pagination.pageSize}&${filterQuery}`
        });

        if (res?.code === 1000) {
          this.logs = res.data.content || []
          this.pagination.pageSize =  res.data?.size || 20
          this.pagination.pageNumber = res.data?.number + 1 || 1
          this.pagination.total = res.data?.totalElements
        }
        this.searchLogsCount++
      } catch (e) {
        this.$message.error('Error retrieving meter totalizer logs')
      } finally {
        this.dataLoading = false
      }
    },
    handleCompanyChange (companyId) {
      this.resetPagination()
      this.resetLogs()
      this.shipImoNo = null
      this.dateRange = null
      this.meterSerialNo = null
      this.fromDt = null
      this.toDt = null,
      this.meterSerialNos = []
      this.getShipsByCompany(companyId)
    },
    handleShipChange (shipImoNo) {
      this.resetPagination()
      this.resetLogs()
      this.meterSerialNo = null
      this.meterSerialNos = []
      this.getMeterSerialNoByImo()
    },
    handleDateRangeChange (dateRange) {
      this.resetPagination()
      this.resetLogs()
      this.meterSerialNo = null
      this.meterSerialNos = []
      if (!dateRange) return
      const [fromDt, toDt] = dateRange
      fromDt.setHours(0, 0, 0, 0)
      toDt.setHours(23, 59, 59, 999)
      this.fromDt = toSgUtcDate(fromDt).toISOString()
      this.toDt = toSgUtcDate(toDt).toISOString()
      this.getMeterSerialNoByImo()
    },
    handleMeterSerialNoChange (meterSerialNo) {
      this.resetPagination()
      this.resetLogs()
    },
    async exportLogs () {
      this.dataLoading = true;

      try {
        const res = await this.$request.getFile({
          url: `${this.$apis.meterTotalizerLogs}/report?shipId=${this.shipId}&imoNo=${this.shipImoNo}&fromDt=${this.fromDt}&toDt=${this.toDt}&meterSerialNo=${this.meterSerialNo}&timezoneOffset=${this.timezoneOffset}`
        });

        const blob = new Blob([res], { type: res.type })
        const url = URL.createObjectURL(blob)
        this.openBlobUrlInNewTab(url)
        // revokeObjectURL after 20min
        setTimeout(() => {
          URL.revokeObjectURL(url)
        }, 1200000)
      } catch (e) {
        this.$message.error('Error generating PDF Report')
      } finally {
        this.dataLoading = false
      }
    },
    handlePageSizeChange(size) {
      this.pagination.pageSize = size
      if (!this.isLogSearchable) return
      this.getLogs()
    },
    handlePageNumberChange(number) {
      this.pagination.pageNumber = number
      if (!this.isLogSearchable) return
      this.getLogs()
    },
    resetPagination () {
      this.pagination.pageNumber = 1
      this.pagination.total = 0
    },
    resetLogs () {
      this.logs = []
      this.searchLogsCount = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-label {
  font-weight: 500;
  padding-left: 2px;
  padding-bottom: 4px;
}

</style>
