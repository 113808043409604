var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dataLoading,
          expression: "dataLoading",
        },
      ],
      staticStyle: { overflow: "auto" },
    },
    [
      _c("h1", [_vm._v("Meter Totalizer Log")]),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c("div", { staticStyle: { display: "flex", gap: "5px" } }, [
            _vm.permissions.hasSysAdminPermission
              ? _c(
                  "div",
                  [
                    _vm._m(0),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Select", filterable: "" },
                        on: { change: _vm.handleCompanyChange },
                        model: {
                          value: _vm.companyId,
                          callback: function ($$v) {
                            _vm.companyId = $$v
                          },
                          expression: "companyId",
                        },
                      },
                      _vm._l(_vm.companies, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.companyName, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _vm._m(1),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      filterable: "",
                      disabled: !_vm.companyId,
                    },
                    on: { change: _vm.handleShipChange },
                    model: {
                      value: _vm.shipImoNo,
                      callback: function ($$v) {
                        _vm.shipImoNo = $$v
                      },
                      expression: "shipImoNo",
                    },
                  },
                  _vm._l(_vm.ships, function (item) {
                    return _c("el-option", {
                      key: item.imo,
                      attrs: { label: item.shipName, value: item.imo },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._m(2),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    format: "yyyy-MM-dd",
                    "range-separator": "To",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    disabled: !_vm.shipImoNo,
                  },
                  on: { change: _vm.handleDateRangeChange },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._m(3),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Select" },
                    on: { change: _vm.handleMeterSerialNoChange },
                    model: {
                      value: _vm.meterSerialNo,
                      callback: function ($$v) {
                        _vm.meterSerialNo = $$v
                      },
                      expression: "meterSerialNo",
                    },
                  },
                  _vm._l(_vm.meterSerialNos, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticStyle: { "padding-top": "20px" } }),
                _c("el-button", {
                  attrs: {
                    icon: "el-icon-search",
                    circle: "",
                    disabled: !_vm.isLogSearchable,
                  },
                  on: { click: _vm.getLogs },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c("div", { staticStyle: { "padding-top": "20px" } }),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    plain: "",
                    disabled: _vm.logs.length === 0,
                  },
                  on: { click: _vm.exportLogs },
                },
                [_vm._v("View PDF")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 0px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
                height: "calc(100vh - 15vw)",
                overflow: "scroll",
              },
              attrs: {
                data: _vm.logs,
                stripe: "",
                "empty-text":
                  !_vm.isLogSearchable || _vm.searchLogsCount == 0
                    ? "Please fill in the required fields and click the search button to proceed"
                    : "No data found",
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "dateTime",
                  label: "Date / Time",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.dateTime
                                ? _vm.serverSGDateTimeFormat(
                                    new Date(scope.row.dateTime)
                                  )
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "fuelTypeCode",
                  label: "Product / Grade",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "address",
                  label: "Terminal Loaded / Vessel Delivered",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.jobType === null
                                ? scope.row.otherType
                                : scope.row.jobType === "LOADING"
                                ? scope.row.floaterName || scope.row.location
                                : scope.row.receivingShipName
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "bunkerMeteringTicketNo",
                  label: "BMT No.",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.bunkerMeteringTicketNo || "-")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "address",
                  label: "BDN No. / CQ No.",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.jobType === null
                                ? "-"
                                : scope.row.jobType === "LOADING"
                                ? scope.row.cqNo
                                : scope.row.bdnNo
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "header-align": "center",
                    label: "Loading Totalizer Reading",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      prop: "mtX",
                      label: "Start",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      prop: "mtY",
                      label: "End",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "header-align": "center",
                    label: "Delivery Totalizer Reading",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      prop: "mtA",
                      label: "Start",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      prop: "mtB",
                      label: "End",
                    },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "quantity",
                  label: "Meter Qty Loaded / Delivered",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "cargoTankSequence",
                  label: "Cargo Tank Sequence",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.cargoTankSequence || "-")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "cargoOfficerName",
                  label: "Cargo Officer",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  prop: "remarks",
                  label: "Remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "flex-end" } },
        [
          _c("el-pagination", {
            staticStyle: { position: "absolute" },
            attrs: {
              background: "",
              "page-size": _vm.pagination.pageSize,
              "page-sizes": [5, 10, 20, 50, 100],
              "current-page": _vm.pagination.pageNumber,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handlePageSizeChange,
              "current-change": _vm.handlePageNumberChange,
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-label" }, [
      _vm._v("Company "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-label" }, [
      _vm._v("Ship "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-label" }, [
      _vm._v("Date Range "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-label" }, [
      _vm._v("Meter Serial No. "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }